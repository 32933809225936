import React from "react"
import PropTypes from "prop-types"

import Header from "./header"

import "../styles/main.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header/>
        <main class="main">{children}</main>
        <footer>
          © {new Date().getFullYear()} Irma Servatius, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>.
          Favicon adopted from <a href="https://upload.wikimedia.org/wikipedia/commons/b/b0/CClef.svg">CClef</a> by <a href="https://commons.wikimedia.org/wiki/File:CClef.svg" title="via Wikimedia Commons">っ</a> / <a href="http://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA</a>
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
