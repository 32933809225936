import PropTypes from "prop-types"
import React from "react"
import SEO from "./seo"

const Header = ({ siteTitle }) => (
  <header>
    <SEO/>
  <div class="head">
    <a href=".">Irma Servatius - viola</a>
    <img src="/images/header.jpg" height="230" width="770"/>
    <div id="nav">
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/cv">Lebenslauf</a></li>
        <li><a href="/repertoire">Repertoire</a></li>
        <li><a href="/listen">Aufnahmen</a></li>
        <li><a href="/fotos">Fotos</a></li>
        <li><a href="/lessons">Unterricht</a></li>
        <li><a href="/contact">Etc.</a></li>
      </ul>
    </div>
  </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
